import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
// 高德地图
import VueAMap from 'vue-amap'
// 引入 全局样式
import './assets/css/index.less'
// 引入 element-ui 组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  // key: 'ab1284a712dd78d31271899cf3cc7ef3',
  key: '3c012699a6f96daa6e36c7c98d33f9aa',
  plugin: ['AMap.Autocomplete', ' AMap.LabelMarker', 'AMap.Weather', 'AMap.DistrictLayer', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.MarkerClusterer', 'AMap.DistrictSearch', 'AMap.Geocoder', 'AMap.Polygon', 'AMap.MouseTool'],
  // 默认高德 sdk 版本为 1.4.4
  uiVersion: '1.0.11', // ui库版本，不配置不加载,
  v: '1.4.4'
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  { path: '/', redirect: '/matrix-page' },
  // { path: '*', redirect: '/matrix-page' }, // 添加通配符路由
    // 首页 平台管理
  {
    path: '/matrix-page',
    name: 'matrixPage',
    component: () => import('../views/matrixPage/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/lebo-park',
    component: () => import('../views/layout/index.vue'),
    children: [
      {
        path: '/park-digital',
        name: 'parkDigital',
        component: () => import('../views/parkDigital/index.vue')
      },
      {
        path: '/lebo-park',
        name: 'leboPark',
        component: () => import('../views/leboPark/index.vue')
      },
      {
        path: '/parking-space-assets',
        name: 'parkingSpaceAssets',
        component: () => import('../views/parkingSpaceAssets/index.vue')
      },
      {
        path: '/park-sale',
        name: 'parkSale',
        component: () => import('../views/parkSale/index.vue')
      },
      {
        path: '/park-share',
        name: 'parkShare',
        component: () => import('../views/parkShare/index.vue')
      },
      {
        path: '/park-urbanize',
        name: 'parkUrbanize',
        component: () => import('../views/parkUrbanize/index.vue')
      },
      {
        path: '/intellect-model',
        name: 'intellectModel',
        component: () => import('../views/intellectModel/index.vue')
      },
      {
        path: '/park-data-model',
        name: 'parkDataModel',
        component: () => import('../views/parkDataModel/index.vue')
      },
      {
        path: '/statistics-manage',
        name: 'statisticsManage',
        component: () => import('../views/statisticsManage/index.vue')
      },
      {
        path: '/device-manage',
        name: 'deviceManage',
        component: () => import('../views/deviceManage/index.vue')
      },
      {
        path: '/park-chat',
        name: 'parkChat',
        component: () => import('../views/parkChat/index.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to, from, '路由拦截')
//   if (to.path === from.path) {
//     return next(false); // 取消导航
//   }
//   next()
// })

export default router
